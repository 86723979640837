body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 200px;
  width: 200px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(80, 209, 190);
  background: linear-gradient(
    90deg,
    rgb(85, 224, 203) 0%,
    rgb(94, 105, 108) 50%,
    rgb(39, 123, 154) 100%
  );
}

.App {
display: flex;
flex-direction: column;
text-align: center;
}

.NewCharacter{
display: flex;
flex-direction: column;
text-align: center; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
